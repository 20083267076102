export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

const eSign: BreadcrumbsItem = {
  label: "eSign",
  path: "/esign",
  dustinIcon: "pen",
};

const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
  {
    label: eSign.label,
    dustinIcon: eSign.dustinIcon,
    match: {
      path: eSign.path,
      exact: true,
    },
  },
];

export default breadcrumbsConfig;
