import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const StatusCard = () => {
  const { t } = useTranslation("esignDashboardPage");
  const { requestStatus, status } = useSelector(dashboardSelectors.getStatus);

  const isOkStatus = useMemo(() => status.toLowerCase() === "ok", [status]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(dashboardThunks.getStatusInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card className={styles.esignCard} title={t("statusCardTitle")} data-testid="statusCardContent">
      <div className={styles.esignCardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.esignStatusBlock}>
            <div className={cn(styles.esignStatusCircle, { [styles.esignOkStatusCircle]: isOkStatus })}>{status}</div>
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default StatusCard;
