import React from "react";
import cn from "classnames";
import StatusCard from "./cards/StatusCard";
import UserCountCard from "./cards/UserCountCard";
import Documents30DaysCard from "./cards/Documents30DaysCard";
import ClosedDocuments6Months from "./cards/ClosedDocuments6Months";

import styles from "./styles.module.css";

const DashboardCharts = () => {
  return (
    <div className={styles.esignChartsContainer}>
      <div className={cn(styles.esignChartsItem, styles.esignSmallCard)}>
        <StatusCard />
      </div>
      <div className={cn(styles.esignChartsItem, styles.esignSmallCard)}>
        <UserCountCard />
      </div>
      <div className={cn(styles.esignChartsItem, styles.esignSmallCard)}>
        <Documents30DaysCard />
      </div>
      <div className={cn(styles.esignChartsItem, styles.esignBigCard)}>
        <ClosedDocuments6Months />
      </div>
    </div>
  );
};

export default DashboardCharts;
