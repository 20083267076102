import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "pages/Dashboard";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/esign" exact component={Dashboard} />
    <Route path="*">
      <Redirect to="/esign" />
    </Route>
  </Switch>
);

export default AppRouter;
