// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_notificationCard__Ni2xA {
  min-width: 300px;
  max-width: 400px;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
}

.styles_notificationCard__Ni2xA:hover {
  cursor: pointer;
}

.styles_notificationCardTitle__OVuUz {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 0;
  opacity: 0.7;
}

.styles_notificationCardIcon__2tu7\\+ {
  font-size: 20px;
  margin-inline-end: 8px;
}

.styles_notificationCardDescr__tHgzu {
  padding-bottom: 8px;
  font-size: 0.85rem;
}
`, "",{"version":3,"sources":["webpack://./src/containers/Notification/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yGAAyG;AAC3G;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".notificationCard {\n  min-width: 300px;\n  max-width: 400px;\n  padding: 6px 16px;\n  border-radius: 4px;\n  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);\n}\n\n.notificationCard:hover {\n  cursor: pointer;\n}\n\n.notificationCardTitle {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  padding: 8px 0;\n  opacity: 0.7;\n}\n\n.notificationCardIcon {\n  font-size: 20px;\n  margin-inline-end: 8px;\n}\n\n.notificationCardDescr {\n  padding-bottom: 8px;\n  font-size: 0.85rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationCard": `styles_notificationCard__Ni2xA`,
	"notificationCardTitle": `styles_notificationCardTitle__OVuUz`,
	"notificationCardIcon": `styles_notificationCardIcon__2tu7+`,
	"notificationCardDescr": `styles_notificationCardDescr__tHgzu`
};
export default ___CSS_LOADER_EXPORT___;
