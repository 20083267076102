import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Icon } from "@skyportal/ui-kit";
import { makeTitleFromBreadcrumbs } from "./utils";
import breadcrumbsConfig from "./config";

import styles from "./styles.module.css";

const Breadcrumbs = () => {
  const { t } = useTranslation("breadcrumbs");
  const { pathname } = useLocation();
  const configItem = breadcrumbsConfig.find((item) => matchPath(pathname, item.match));

  if (!configItem) {
    return null;
  }

  const documentTitle = makeTitleFromBreadcrumbs(configItem);

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <nav data-testid="BreadCrumbs" className={styles.root}>
        {configItem.crumbs &&
          configItem.crumbs.map((item) => (
            <Fragment key={item.path}>
              <div className={styles.esignBreadcrumbs}>
                <Link to={item.path} className={styles.breadcrumbLink}>
                  {item.dustinIcon && <Icon icon={item.dustinIcon} className={styles.base64LinkIcon} />}
                  {t(item.label)}
                </Link>
              </div>

              <span className={styles.esignBreadcrumbs}>{" > "}</span>
            </Fragment>
          ))}
        <div className={styles.esignBreadcrumbs}>
          {configItem.dustinIcon && <Icon icon={configItem.dustinIcon} className={styles.base64LinkIcon} />}
          {t(configItem.label)}
        </div>
      </nav>
    </>
  );
};

export default Breadcrumbs;
