// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@skyportal/ui-kit/dist/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: var(--color-grey-02);
  font-family: var(--font-family);
  font-size: 16px;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.app-container > main {
  flex-grow: 1;
  padding-top: 50px;
}

.SkyPortalModal__body > div > ul {
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,sCAAsC;EACtC,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@import \"~@skyportal/ui-kit/dist/fonts.css\";\n\n* {\n  padding: 0;\n}\n\nhtml,\nbody {\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  background-color: var(--color-grey-02);\n  font-family: var(--font-family);\n  font-size: 16px;\n}\n\n.app-container {\n  display: flex;\n  min-height: 100vh;\n}\n\n.app-container > main {\n  flex-grow: 1;\n  padding-top: 50px;\n}\n\n.SkyPortalModal__body > div > ul {\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
