// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_root__HwqIO {
  display: flex;
  gap: 8px;
}

@media (max-width: 767px) {
  .styles_root__HwqIO {
    padding: 8px;
    border-radius: 4px;
    margin: 8px 0;
    background-color: #d2d6de;
    font-size: 14px;
  }
}

.styles_esignBreadcrumbs__T1Q3h {
  display: flex;
  align-items: center;
  color: black;
  font-size: 12px;
}

.styles_breadcrumbLink__cdONu {
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
}

.styles_base64LinkIcon__a\\+Z3X {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/Breadcrumbs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".root {\n  display: flex;\n  gap: 8px;\n}\n\n@media (max-width: 767px) {\n  .root {\n    padding: 8px;\n    border-radius: 4px;\n    margin: 8px 0;\n    background-color: #d2d6de;\n    font-size: 14px;\n  }\n}\n\n.esignBreadcrumbs {\n  display: flex;\n  align-items: center;\n  color: black;\n  font-size: 12px;\n}\n\n.breadcrumbLink {\n  display: flex;\n  align-items: center;\n  color: black;\n  text-decoration: none;\n}\n\n.base64LinkIcon {\n  width: 12px;\n  height: 12px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles_root__HwqIO`,
	"esignBreadcrumbs": `styles_esignBreadcrumbs__T1Q3h`,
	"breadcrumbLink": `styles_breadcrumbLink__cdONu`,
	"base64LinkIcon": `styles_base64LinkIcon__a+Z3X`
};
export default ___CSS_LOADER_EXPORT___;
